import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.46:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';

let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.46:5000/api/';
}
class AssessmentService {
    async getAssessments(emp_id,role) {
        try {
            const res = await axios.get(API_URL + 'assessments/' + emp_id + '/' + role,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getActiveAssessments(emp_id,role) {
        try {
            const res = await axios.get(API_URL + 'assessments/active/' + emp_id + '/' + role,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getUserAssignedToAssessments(emp_id,assessment_id,role) {
        try {
            const res = await axios.get(API_URL + 'assessments/users/' + emp_id + '/' + assessment_id + '/' + role,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    async getAssessmentQuestions(emp_id,assessment_id) {
        try {
            const res = await axios.get(API_URL + 'assessments/questions/' + emp_id + '/' + assessment_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async getUserResponses(emp_id,assessment_id) {
        try {
            const res = await axios.post(API_URL + 'assessments/responses/' + emp_id + '/' + assessment_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }
    
    async postUserResponse(emp_id,assessment_id) {
        try {
            const res = await axios.post(API_URL + 'assessments/' + emp_id + '/' + assessment_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async updateAssessmentAndResponses(data) {
        try {
            const res = await axios.post(API_URL + 'assessments/responses', data,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    async getEmployeeStats(emp_id) {
        try {
            const res = await axios.get(API_URL + 'assessments/emp/stats/' + emp_id,
            { 
                headers: AuthService.authHeader()
            });
            //console.log(res.data)
            return res.data
        }
        catch(err) {
            console.log(err.response)
            return err.response
        }
    }

    // async updateTeamLeadResponse(emp_id,assessment_id,data) {
    //     try {
    //         const res = await axios.post(API_URL + 'assessment/' + emp_id + '/' + assessment_id, data,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }

    // async updateFinalResponse(emp_id,assessment_id,data) {
    //     try {
    //         const res = await axios.post(API_URL + 'assessment/' + emp_id + '/' + assessment_id, data,
    //         { 
    //             headers: AuthService.authHeader()
    //         });
    //         //console.log(res.data)
    //         return res.data
    //     }
    //     catch(err) {
    //         console.log(err.response)
    //         return err.response
    //     }
    // }
}



export default new AssessmentService();